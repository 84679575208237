import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const FleetSection = () => {
  const data = useStaticQuery(graphql`
    query {
      fleetDesktop: file(relativePath: { eq: "home/fleet-desktop.png" }) {
        childImageSharp {
          fluid(maxWidth: 857, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
        publicURL
      }
    }
  `)

  return (
    <section className="section section-fleet">
      <div className="container">
        <h2 className="title">
          <span className="has-text-blue">Zul+ </span>
          <br />
          <span className="has-text-blue">também </span>
          <br />
          <span className="has-text-white">é para </span>
          <br />
          <span className="has-text-white">frotistas</span>
        </h2>

        <div className="is-hidden-desktop">
          <p className="subtitle">
            A liderança da plataforma também está presente no mundo B2B.
          </p>
          <p className="p">
            25 mil empresas já utilizam nossas soluções. A plataforma Zul+
            permite fácil gestão de frotas e gera valor para todos os players
            conectados.
          </p>
        </div>

        <div className="is-hidden-mobile columns">
          <div className="colmun is-one-third">
            <p className="subtitle">
              A liderança da plataforma também está <br />
              presente no mundo B2B. <br />
              <br />
              25 mil empresas já utilizam nossas soluções. <br />
              A plataforma Zul+ permite fácil gestão de frotas <br />e gera
              valor para todos os players conectados.
            </p>
          </div>
        </div>

        <p className="p cta-container">
          <a
            className="button is-rounded is-large black-cta"
            href="https://zuldigital.typeform.com/to/cPeNLU?city=CWB&utm_source=site&utm_medium=home&utm_campaign=institucional&utm_content=ctafrotistas"
            target="_blank"
            rel="noreferrer"
          >
            Conheça nossa solução
          </a>
        </p>

        <div className="is-hidden-mobile">
          <Img
            className="fleet"
            fluid={data.fleetDesktop.childImageSharp.fluid}
            alt="Frota"
          />
        </div>
      </div>
    </section>
  )
}

export default FleetSection
