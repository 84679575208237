import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"

// sections
import MainSection from "./sections/home/main"
import ScreenshotSection from "./sections/home/screenshot"
import AppSection from "./sections/home/app"
import DownloadSection from "./sections/home/download"
import UsersSection from "./sections/home/users"
import BestAppSection from "./sections/home/best-app"
import FleetSection from "./sections/home/fleet"
import NewCitiesSection from "./sections/home/new-cities"
import OpenPlatformSection from "./sections/home/open-platform"

const IndexPage = () => (
  <Layout contentClass="page page-home">
    <SEO title="Home" />

    <Header siteTitle={`Zul Digital`} menu={true} />

    <MainSection />
    <ScreenshotSection />
    <AppSection />
    <DownloadSection />
    <UsersSection />
    <BestAppSection />
    <FleetSection />
    <NewCitiesSection />
    <OpenPlatformSection />

    <Footer />
  </Layout>
)

export default IndexPage
