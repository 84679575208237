import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const DownloadSection = () => {
  const data = useStaticQuery(graphql`
    query {
      starIcon: file(relativePath: { eq: "home/icon-star.svg" }) {
        publicURL
      }
      appStoreIcon: file(relativePath: { eq: "icons/app-store.svg" }) {
        publicURL
      }
      googlePlayIcon: file(relativePath: { eq: "icons/google-play.svg" }) {
        publicURL
      }
    }
  `)

  return (
    <section className="section section-download-app is-hidden-desktop">
      <div className="container">
        <div className="download-links">
          <p className="has-text-centered">
            <a
              href="https://www.zuldigital.com.br/app/sao?utm_source=site&utm_medium=home&utm_campaign=institucional&utm_content=appstorelogo"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={data.appStoreIcon.publicURL}
                width="149"
                height="41"
                alt="Apple App Store"
              />
            </a>
          </p>
          <p className="has-text-centered">
            <a
              href="https://www.zuldigital.com.br/app/sao?utm_source=site&utm_medium=home&utm_campaign=institucional&utm_content=googleplaylogo"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={data.googlePlayIcon.publicURL}
                width="149"
                height="41"
                alt="Google Play"
              />
            </a>
          </p>
        </div>

        <div className="reviews">
          <div className="is-clearfix has-text-centered">
            <ul className="reviews-stars">
              <li className="reviews-star is-pulled-left">
                <img src={data.starIcon.publicURL} alt="Estrela" />
              </li>
              <li className="reviews-star is-pulled-left">
                <img src={data.starIcon.publicURL} alt="Estrela" />
              </li>
              <li className="reviews-star is-pulled-left">
                <img src={data.starIcon.publicURL} alt="Estrela" />
              </li>
              <li className="reviews-star is-pulled-left">
                <img src={data.starIcon.publicURL} alt="Estrela" />
              </li>
              <li className="reviews-star is-pulled-left">
                <img src={data.starIcon.publicURL} alt="Estrela" />
              </li>
            </ul>
          </div>
          <p className="reviews-p has-text-centered">
            + de 80 mil <span className="is-block">avaliações</span>
          </p>
        </div>
      </div>
    </section>
  )
}

export default DownloadSection
