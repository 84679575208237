import React from "react"

const OpenPlatformSection = () => (
  <section className="section section-open-platform">
    <div className="container">
      <h2 className="title">
        <span className="has-text-blue">Plataforma</span> <br />
        <span className="has-text-white">aberta</span>
      </h2>

      <p className="subtitle">
        APIs prontas para rápida integração com parcerios estratégicos.
      </p>

      <p className="p">
        Montadoras, rede de postos de combustíveis e seguradoras podem oferecer
        uma experiência única para seus clientes dentro do app e fazer parte da
        rotina de quem dirige.
      </p>

      <p className="p cta-container">
        <a
          className="button is-rounded is-large black-cta has-transparent-bg has-border-blue"
          href="https://zuldigital.typeform.com/to/UDLRlL"
          target="_blank"
          rel="noreferrer"
        >
          Conheça nossa solução
        </a>
      </p>
    </div>
  </section>
)

export default OpenPlatformSection
