import React from "react"

const BestAppSection = () => (
  <section className="section section-best-app">
    <div className="container">
      <h2 className="title">
        <span className="has-text-white">Principal app</span> <br />
        <span className="has-text-blue">do segmento</span>
      </h2>

      <div className="columns">
        <div className="column is-8-desktop is-12-mobile">
          <p className="subtitle">
            Com 2 milhões de usuários, o Zul+ é hoje referência de super app
            para carros.
          </p>
          <p className="p">
            Alta aceitação se deve à usabilidade do app e grande diversidade de
            serviços disponíveis para quem tem um carro.
          </p>
        </div>
      </div>
    </div>
  </section>
)

export default BestAppSection
