import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      instagramIcon: file(relativePath: { eq: "home/icon-instagram.svg" }) {
        publicURL
      }
      facebookIcon: file(relativePath: { eq: "home/icon-facebook.svg" }) {
        publicURL
      }
      linkedinIcon: file(relativePath: { eq: "home/icon-linkedin.svg" }) {
        publicURL
      }
    }
  `);

  return (
  <footer className="footer">
    <div className="container">
      <div className="social-bar">
        <div className="columns is-mobile">
          <div className="column has-text-centered">
            <a
              href="https://instagram.com/zuldigital"
              target="_blank"
              className="icon"
              title="Instagram"
              rel="noreferrer"
            >
              <img
                src={data.instagramIcon.publicURL}
                alt="Instagram"
              />
            </a>
          </div>
          <div className="column has-text-centered">
            <a
              href="https://facebook.com/appzuldigital"
              target="_blank"
              className="icon"
              title="Facebook"
              rel="noreferrer"
            >
              <img
                src={data.facebookIcon.publicURL}
                alt="Facebook"
              />
            </a>
          </div>
          <div className="column has-text-centered">
            <a
              href="https://www.linkedin.com/company/zul-digital/"
              target="_blank"
              className="icon"
              title="Linkedin"
              rel="noreferrer"
            >
              <img
                src={data.linkedinIcon.publicURL}
                alt="Linkedin"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="list-menu">
        <ul className="list">
          <li className="list-item">
            <a href="https://apply.workable.com/zul/" target="_blank" rel="noreferrer">
              Trabalhe Conosco
            </a>
          </li>
          <li className="list-item">
            <a href="https://zuldigital.typeform.com/to/UDLRlL" target="_blank" rel="noreferrer">
              Comercial
            </a>
          </li>
        </ul>
      </div>
      <div className="copy">
        <strong>ON Tecnologia de Mobilidade Urbana S/A</strong>{" "}
        <br className="is-hidden-mobile" />
        CNPJ - 26.054.490/0001-00
      </div>
    </div>
  </footer>
)}

export default Footer
