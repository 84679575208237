import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"

const Header = ({ siteTitle, menu }) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.svg" }) {
        publicURL
      }
    }
  `)

  const [showMenu, setShowMenu] = useState(false)

  const menuClick = (event) => {
    event.preventDefault();

    console.log('burger click');

    setShowMenu(!showMenu)
  }

  return (
    <header className="header main-header">
      <div className="container">
        <nav className="navbar" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <Link to="/" className="main-logo" alt={siteTitle}>
              <img src={data.logo.publicURL} alt={siteTitle} />
            </Link>

            {menu ? (
              <a
                href="/#"
                role="button"
                className="navbar-burger burger zul-burger"
                aria-label="menu"
                aria-expanded="false"
                data-target="navbarBasicExample"
                onClick={menuClick}
              >
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
              </a>
            ) : (
              ""
            )}
          </div>

          {menu ? (
            <div className={`navbar-menu ${showMenu ? "is-active" : ""}`}>
              <a
                href="/#"
                className="close is-hidden-desktop"
                onClick={menuClick}
              >
                &times;
              </a>
              <div className="navbar-start">
                <a
                  className="navbar-item"
                  href="https://www.zuldigital.com.br/blog/"
                  target="_blank"
                  rel="noreferrer"
                  title="Blog"
                >
                  <span className="is-hidden-mobile">
                    &nbsp; <br />
                  </span>
                  Blog
                </a>
                <a
                  className="navbar-item"
                  href="/zul-mais"
                  title="Zul +"
                >
                  <span className="is-hidden-mobile">
                    &nbsp; <br />
                  </span>
                  Zul +
                </a>
                <a
                  className="navbar-item"
                  href="https://painel.zuldigital.com.br/"
                  target="_blank"
                  rel="noreferrer"
                  title="Acesso ao Painel"
                >
                  <span className="is-hidden-mobile">
                    &nbsp; <br />
                  </span>
                  Acesse
                </a>
                <a
                  className="navbar-item"
                  href="https://zuldigital.typeform.com/to/UDLRlL"
                  target="_blank"
                  rel="noreferrer"
                  title="Fale Conosco"
                >
                  Fale <br className="is-hidden-mobile" />
                  Conosco
                </a>
              </div>
            </div>
          ) : (
            ""
          )}
        </nav>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
