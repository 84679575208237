import React from "react"

const NewCitiesSection = () => (
  <section className="section section-new-cities">
    <div className="container">
      <h2 className="title">
        <span className="has-text-blue">Fácil</span> <br />
        <span className="has-text-blue">implantação</span> <br />
        <span className="has-text-white">em novas</span> <br />
        <span className="has-text-white">cidades</span>
      </h2>

      <p className="subtitle is-hidden-desktop">
        Sua cidade precisa de um sistema de estacionamento rotativo mais
        moderno?
      </p>

      <p className="p is-hidden-desktop">
        Nosso app foi desenvolvido para permitir uma rápida implantação,
        trazendo controle, transparência e excelente usabilidade para gestores e
        população.
      </p>

      <div className="is-hidden-mobile">
        <div className="columns">
          <div className="column">
            <p className="subtitle">
              A liderança da plataforma <br />
              também está presente no mundo <br />
              B2B. 25 mil empresas já utilizam <br />
              nossas soluções.
            </p>
          </div>
          <div className="column">
            <p className="subtitle">
            Somos a principal AutoTech do Brasil <br />
            não por acaso. Além de simplificar a vida <br />
            do motorista, também oferecemos soluções <br />
            inteligentes para empresas, parceiros <br />
            estratégicos e prefeituras.
            </p>
          </div>
        </div>
      </div>

      <p className="p cta-container">
        <a
          className="button is-rounded is-large black-cta"
          href="https://zuldigital.typeform.com/to/UDLRlL"
          target="_blank"
          rel="noreferrer"
        >
          Conheça nossa solução
        </a>
      </p>
    </div>
  </section>
)

export default NewCitiesSection;
