import React from "react"

const UsersSection = () => (
  <section className="section section-users">
      <div className="container">
        <h2 className="title">
          <span className="has-text-white">+ de</span> <br />
          <span className="has-text-white">2 milhões</span> <br />
          <span className="has-text-blue">de usuários</span>
        </h2>

        <div className="columns">
          <div className="column is-8-desktop is-12-mobile">
            <div className="is-hidden"></div>
            <p className="subtitle">
              utilizam o Zul+ no seu <br />
              dia a dia.
            </p>

            <p className="p">
            O app é referência para motoristas que precisam resolver pendências relacionadas ao veículo, sem sair de casa.
            </p>
          </div>
        </div>
      </div>
    </section>
);

export default UsersSection;
